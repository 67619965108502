<template>
  <a-card :bordered="false" class="card-area">
    <div>

      <common-table
        ref="table"
        path="banner"
        :columns="columns" >

        <template slot="search">
          <a-button ghost type="primary" @click="add" >新增</a-button>
        </template>

        <template slot="image" slot-scope="{record}">
          <img :src="record.image" class="banner-img" />
        </template>

        <template slot="enable" slot-scope="{record}">
          <a-switch
            @change="checked=>onChange(checked,record)"
            v-model="record.enable"
            size="small"
          />
        </template>

        <template slot="operation" slot-scope="{record}">
          <action-edit  @click="edit(record)"></action-edit>
          <action-delete  @click="remove(record)"></action-delete>
        </template>

      </common-table>

    </div>

    <banner-edit ref="bannerEdit" @success="getList"></banner-edit>
  </a-card>
</template>
<script>
import BannerEdit from './BannerEdit'
export default {
  components: {
    BannerEdit
  },
  data () {
    return {

    }
  },
  created () {
  },
  computed: {
    columns () {
      return [
        {
          title: '图片',
          dataIndex: 'image',
          scopedSlots: { customRender: 'image' }
        },
        {
          title: '标题',
          dataIndex: 'title',
        },
        {
          title: '跳转链接',
          dataIndex: 'link',
        },
        {
          title: '排序',
          dataIndex: 'sort',
          width: 65
        },
        {
          title: '是否启用',
          dataIndex: 'enable',
          scopedSlots: { customRender: 'enable' }
        },

        {
          title: '操作',
          dataIndex: 'operation',
          scopedSlots: { customRender: 'operation' }
        }
      ]
    }
  },
  methods: {
    add () {
      this.$refs.bannerEdit.show()
    },

    view (record) {

    },
    edit (record) {
      this.$refs.bannerEdit.show(record)
    },
    onChange (checked, record) {
      var that = this
      record = { ...record, enable: checked }
      this.$put('/banner', { ...record })
        .then(res => {
          that.getList()
        })
        .catch(err => {
          console.log('err', err)
        })
    },
    remove (record) {
      var that = this
      this.$confirm({
        title: '是否删除轮播图?',
        content: '该操作不可撤销',
        centered: true,
        onOk () {
          that.$delete('banner/' + record.id).then(() => {
            that.$message.success('删除成功')
            that.getList()
          })
        }
      })
    },
    getList () {
      this.$refs.table.getData()
    }

  }
}
</script>
<style lang="less" scoped>
@import '../../../../static/less/Common';

.banner-img {
  object-fit: cover;
  width: 80px;
  height: 60px;
  border-radius: 3px;
}
</style>
